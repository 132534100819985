<template>
  <div>
    <Header page-text="Video"></Header>
    <div v-if="user">
      <div class="card cardInternal">
        <div class="teamName">Kompression</div>
        <div class="dataHeadline">Versuche deine Aktion mit einer Zeitrafferaufnahme, darzustellen -> mit Uhr im Bild! oder stelle die Video Qualität herunter. Ansonsten empfehlen wir die nutzung einer Website (wie dieser) zum verkleinern deiner Videos (allerdings solltest du kurz nachdenken ob du dein Video bei einer 'fremden' Website Konvertieren möchtest): <a href="https://www.freeconvert.com/video-compressor">Freeconvert</a><br> Die Einstellungen:</div>
        <img src="../assets/FreeConv.jpg" alt="Converter Settings"/>
        <div class="button blue" v-on:click="$router.back()">Zurück</div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import {Settings} from 'luxon';

Settings.defaultLocale = 'de'

export default {
  name: "Converter",
  components: {Header},
  data() {
    return {
      user: null,
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }
    });
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;
  width: 90%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
}

.teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.dataHeadline {
  margin-top: 5px;
  text-align: left;
  font-size: 1rem;
  color: black;
}

img {
  margin-top: 5px;
  width: 100%;
  height: auto;
}
.dataMedia a {
  margin-top: 8px;
  font-size: 1rem;
  color:var(--blue);
}

.button{
  margin-top: 10px;
  padding: 10px 15px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.blue{
   background-color: var(--blue);
 }
#noData{
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 80%;
  color: var(--gray);
  padding: 15px 25px;
  max-width: 400px;
  min-width: 270px;
}
</style>