<template>
  <div>
    <div id="header">
      <img src="../assets/logo.png" alt="1.Paderborner-SV" v-on:click="$router.push({name:'home'}).catch(err => {});">
      <div id="pageText">{{ pageText }}</div>
      <div id="menue" v-on:click="menuOpened = true">
        <div id="l1"></div>
        <div id="l2"></div>
        <div id="l3"></div>
      </div>
    </div>
    <div id="menuOverlay" v-if="menuOpened">
      <CloseButton v-on:click.native="menuOpened = false" color="white"></CloseButton>
      <div id="items">
        <div class="item" v-on:click="$router.push({name:'home'}).catch(err => {});menuOpened=false">Home</div>
        <div class="item" v-on:click="$router.push({name:'changeUser'}).catch(err => {});menuOpened=false">Benutzer</div>
        <div class="item" v-on:click="$router.push({name:'attribution'}).catch(err => {});menuOpened=false">Autoren</div>
        <div class="item" v-on:click="logout">Ausloggen</div>
        <div class="item"><a href="https://www.paderborner-sv.de/datenschutz/index.php">Datenschutz</a></div>
        <div class="item"><a href="https://www.paderborner-sv.de/impressum/index.php">Impressum</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/CloseButton";

export default {
  name: "Header",
  components: {CloseButton},
  props: {
    pageText: String
  },
  data() {
    return {
      menuOpened: false
    }
  },
  methods:{
    logout:function (){
      this.$router.push({name:'login'}).catch();this.menuOpened=false; this.$setCookie('jwt', '', 1);
    }
  }
}
</script>

<style scoped>
#header {
  width: 97%;
  max-width: 800px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  margin: 5px auto 0 auto;
}

#header img {
  height: 100%;
  cursor: pointer;
}

#header #pageText {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  font-size: 1.6rem;
}

#header #menue {
  margin: 13px 25px;
  cursor: pointer;
}

#header #l1, #header #l2, #header #l3 {
  background-color: var(--blue);
  width: 28px;
  height: 4px;
  border-radius: 2px;
}

#header #l2, #header #l3 {
  margin-top: 6px;
}

#header #l2 {
  margin-left: 4px;
}

/*mobile menu*/
#menuOverlay {
  position: absolute;
  top:0;
  z-index: 200;
  height: 100vh;
  background-color: var(--gray);
  width: 100%;
  transition: all .03s;
}
#items{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.item{
  color: white;
  font-size: 1.4rem;
  line-height: 2.5rem;
  cursor: pointer;
}
.item:hover{
  text-decoration: underline;
}
.item a{
  color: white;
  text-decoration: none;
}
</style>