<template>
  <input type="submit" :value="value">
</template>

<script>
export default {
  name: "InputSubmit",
  props: {
    value: String
  }
}
</script>

<style scoped>
input[type="submit"] {
  width: 60%;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: var(--blue);
  border: 0;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  transition: background-color 0.2s;
  border-radius: 10px;
  font-size: 20px;
  height: 50px;
}

input[type="submit"]:hover {
  background-color: rgba(32, 67, 187,0.6);
  transition: background-color 0.2s;
}
</style>