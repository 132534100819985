<template>
  <div>
    <Header page-text="Ranking"/>
    <div v-if="scoreList && user">
      <div v-if="scoreList.length > 0">
        <div id="notRank">*Teilnehmer(Trainer) außer Konkurrenz. Werden die Plätze 1-3 belegt, geht der Preis an die nächste Person.
        </div>
        <div class="reloadButton blue" v-on:click="getRanking">
          Aktualisieren
        </div>
        <BigCard src="icons/trophy.svg" alt="Pokal"
                 :headline="scoreList[0].firstName +' '+ scoreList[0].lastName + checkIsNotRanked(scoreList[0].uid)"
                 middle-text=""
                 :bottom-text="scoreList[0].points" id="firstPlace"
                 :style="scoreList[0].uid === user.uid?'border: 2px solid var(--blue)':''"></BigCard>
        <ScoreCard v-for="(score, index) in scoreList" :key="index" v-show="index !== 0" :place="(index+1)"
                   :name="score.firstName +' '+ score.lastName + checkIsNotRanked(score.uid)" :points="score.points"
                   :style="score.uid === user.uid?'border: 2px solid var(--blue)':''"></ScoreCard>
      </div>
      <div v-if="scoreList.length === 0" id="noData" class="card">
        Es sind noch keine bestätigten Daten vorhanden.
      </div>
    </div>
  </div>
</template>

<script>
import BigCard from "@/components/BigCard";
import ScoreCard from "@/components/ScoreCard";
import Header from "@/components/Header";

export default {
  name: "ScoreBoard",
  components: {Header, ScoreCard, BigCard},
  data() {
    return {
      user: null,
      scoreList: null,
    }
  },
  methods: {
    getRanking: function () {
      this.scoreList = null
      const formData = new FormData();
      formData.append('tid', this.$route.params.teamid);
      formData.append('bid', this.$route.params.bid);
      formData.append("jwt", this.$getCookie('jwt'))
      this.$http.post(this.$api + "user/get_ranking.php", formData, {},).then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          //console.log(res.data.data);
          this.scoreList = res.data.data;
        }
      })
    },
    checkIsNotRanked: function (uid) {
      let uids = ['4', '6', '8', '60', '18'];
      if (uids.includes(uid)) {
        return '*';
      } else {
        return '';
      }
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }
      this.getRanking();
    });
  }
}
</script>

<style scoped>
#firstPlace {
  border: 2px solid var(--yellow);
}

#noData {
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 80%;
  color: var(--gray);
  padding: 15px 25px;
  max-width: 400px;
  min-width: 270px;
}

.reloadButton {
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.blue {
  background-color: var(--blue);
}

#notRank {
  font-size: 0.7rem;
  color: var(--gray);
  width: 80%;
  max-width: 400px;
  min-width: 270px;
  margin: 10px auto;
}
</style>