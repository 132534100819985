<template>
  <div>
    <Header page-text="Battles"></Header>
    <div v-if="user">
      <div v-if="overlay === false">
        <div id="addButton" v-on:click="overlay = true">Hinzufügen</div>
        <TextCard v-for="battle in battles" :key="battle.bid" :name="battle.name"
                  :info="getEpocheString(battle.start,battle.end)" v-on:click.native="$router.push({name:'challengesManager', params: { teamid: $route.params.teamid, bid: battle.bid }})"></TextCard>
<!--        <div v-if="user.role === 'trainer'" style="margin-top: 40px">-->
<!--        </div>-->
      </div>
      <div class="card cardInternal" v-if="overlay === true">
        <CloseButton v-on:click.native="overlay = false" color="var(--gray)"></CloseButton>
        <div class="teamName">Battle hinzufügen</div>
        <form @submit="checkForm">
          <InputText name="name" id="name" label="" type="text" placeholder="Name"></InputText>
          <div class="label">Beschreibung</div>
          <label>
            <textarea id="description" name="description" rows="4"/>
          </label>
          <div class="label">Start</div>
          <datetime v-model="dateStart" input-id="startDate" :min-datetime="new Date().toISOString()"></datetime>
          <div class="label" v-if="dateStart">Ende</div>
          <datetime v-if="dateStart" v-model="dateEnd" input-id="endDate" :min-datetime="dateStart"></datetime>
          <InputText name="reward" id="reward" label="" type="text" placeholder="Gewinne (Text)"></InputText>
          <div class="errorText" v-if="(error!=='')">{{ error }}</div>
          <InputSubmit value="Hinzufügen"/>
          <div id="hinw">Alle Battles, welche hier erstellt werden, sind nur für deine Mannschaft. Können, aber vom Administrator für alle freigegeben werden.</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import TextCard from "@/components/TextCard";
import InputSubmit from "@/components/InputSubmit";
import InputText from "@/components/InputText";
import {Settings} from 'luxon';
import CloseButton from "@/components/CloseButton";

Settings.defaultLocale = 'de'

export default {
  name: "Battles",
  components: {CloseButton, InputText, InputSubmit, TextCard, Header},
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      user: null,
      error: "",
      battles: null,
      overlay: false
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      //console.log(this.dateStart);
      let name = document.getElementById("name").value;
      let description = document.getElementById("description").value;
      let reward = document.getElementById("reward").value;
      if (name === "") {
        this.error = "Bitte Namen eingeben!";
      } else if (description === "") {
        this.error = "Bitte Beschreibung eingeben!";
      } else if (this.dateStart == null || this.dateStart === "") {
        this.error = "Bitte Startdatum angeben!";
      } else if (this.dateEnd == null || this.dateEnd === "") {
        this.error = "Bitte Enddatum angeben!";
      } else {
        const formData = new FormData();
        formData.append('tid', this.$route.params.teamid);
        formData.append("jwt", this.$getCookie('jwt'));
        formData.append("name", name);
        formData.append("description", description);
        formData.append("start", Math.round((new Date(this.dateStart)).getTime() / 1000).toString());
        formData.append("end", Math.round((new Date(this.dateEnd)).getTime() / 1000).toString());
        formData.append("rewards", reward);

        this.$http.post(this.$api + "user/create_battle.php", formData, {}).then((res) => {
          //console.log(res.data);
          if (res.data.success) {
            this.$snackbar(res.data.data);
            this.getBattles();
            this.overlay = false;
          } else {
            this.error = res.data.error;
          }
        })
      }

    },
    getEpocheString: function (start, end) {
      //var ts = Math.round((new Date()).getTime() / 1000);
      //console.log(ts);
      let startD = new Date()
      let endD = new Date()
      startD.setTime(start * 1000);
      endD.setTime(end * 1000);
      return startD.getDate() + "." + (startD.getMonth()+1) + " - " + endD.getDate() + "." + (endD.getMonth()+1) + "." + endD.getFullYear();
    },
    getBattles:function (){
      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))
      formData.append("tid", this.$route.params.teamid)
      this.$http.post(this.$api + "user/get_battles.php", formData, {},).then((res) => {
        if (res.data.success) {
          this.battles = res.data.data;
        } else {
          this.$snackbar("Etwas ist schief gegangen!");
        }
      });
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      } else if (this.user.role !== 'trainer') {
        this.$router.push({name: "home"});
        return;
      }
      this.getBattles();

    });
  }
}
</script>

<style scoped>
.cardInternal {
  position:relative;
  width: 90%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
}

#addButton {
  margin-top: 20px;
  color: white;
  background-color: var(--blue);
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
}

.teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

form .label {
  text-align: center;
  margin-top: 14px;
  margin-bottom: -10px;
  font-size: 1rem;
}
#hinw{
  text-align: center;
  font-size: 0.7rem;
  color:var(--gray)
}
</style>