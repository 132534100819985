<template>
  <div id="centerHV">
    <div id="register" class="card">
      <img src="../assets/logo.png" width="100px" alt="1. Paderborner Schwimmverein" class="psv_logo">
      <form @submit="checkForm" id="registerForm">
        <InputText type="text" placeholder="Vorname (im Ranking für alle Sichtbar)" name="firstname" id="firstname"
                   label=""></InputText>
        <InputText type="text" placeholder="Nachname (im Ranking für alle Sichtbar)" name="lastname" id="lastname"
                   label=""></InputText>
        <InputText type="text" placeholder="Benutzername" name="username" id="username" label=""></InputText>
        <InputText type="password" placeholder="Passwort" name="password" id="password" label=""></InputText>
        <InputText type="password" placeholder="Passwort Wdh." name="passwordS" id="passwordS" label=""></InputText>
        <div id="checkBox">
          <label><input type="checkbox" name="dsgvo" id="dsgvo" required> Ich stimme den <a
              href="https://www.paderborner-sv.de/datenschutz/index.php">Datenschutzbedingungen</a>
            zu</label>
        </div>
        <div class="errorText" v-if="(error!=='')">{{ error }}</div>
        <InputSubmit value="Registrieren" style="margin-top: 10px"/>
      </form>
    </div>
    <footer>
      <cookie-law theme="dark-lime" buttonText="OK">
        <div slot="message">
          Diese Website benutzt nur notwendige Cookies!
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
import InputText from "@/components/InputText";
import InputSubmit from "@/components/InputSubmit";
import CookieLaw from 'vue-cookie-law';

export default {
  name: "Register",
  components: {InputSubmit, InputText, CookieLaw},
  data() {
    return {
      error: "",
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      let firstName = document.getElementById("firstname").value;
      let lastName = document.getElementById("lastname").value;
      let username = document.getElementById("username").value;
      let password = document.getElementById("password").value;
      let passwordS = document.getElementById("passwordS").value;

      if (firstName === "") {
        this.error = 'Bitte Vornamen eingeben!';
      } else if (lastName === "") {
        this.error = 'Bitte Nachnamen eingeben!';
      } else if (username === "") {
        this.error = 'Bitte Nutzername eingeben!';
      } else if (password === "" || passwordS === "") {
        this.error = 'Bitte Passwort eingeben!';
      } else if (passwordS !== password) {
        this.error = 'Die Passworter stimmen nicht überein!';
      } else if (!password.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})")) {
        this.error = 'Passwort muss min. 1 Kleinbuchstaben, 1 Großbuchstaben, 1 Zahl enthalten und mind. 8 Zeichen lang sein';
      } else {
        this.error = '';

        const formData = new FormData(document.getElementById('registerForm'));
        //console.log(this.$route.params.registerkey);
        formData.append('registerKey', this.$route.params.registerkey);
        this.$http.post(this.$api + "user/create_user.php", formData, {}).then((res) => {
          // console.log(res.data)
          if (!res.data.success) {
            this.error = res.data.error;
          } else {
            //login
            this.$http.post(this.$api + "user/login.php", formData, {}).then((res) => {
              if (!res.data.success) {
                this.error = res.data.error;
              } else {
                this.$setCookie("jwt", res.data.data, 12);
                this.$router.push({name: "home"});
              }
            })
          }
        })
      }
    }
  },created() {
    if (this.$getCookie("jwt") !== "") {
      this.$getUser().then(result => {
            this.user = result
            if (this.user !== null) {
              this.$router.push({name: "home"});
            }
          }
      );
    }
  }
}
</script>

<style scoped>
#centerHV {
  width: 100%;
  height: 100vh;
  background-color: white;
}

#register {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
}

form {
  margin-top: 60px;
}

#checkBox {
  margin-top: 20px;
  font-size: 0.8rem;
}
.psv_logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: -50px;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}
footer{
  position: fixed;
  bottom: 0;
}
</style>