<template>
  <div class="card cardInternal" :style="{border: place > 3?'none':'1px solid var(--yellow)'}">
    <div class="innerFlex">
      <div class="place" :style="{color: place > 3?'var(--ltgray)':'var(--red)'}">{{ place }}</div>
      <div class="name">{{ name }}</div>
    </div>
    <div class="innerFlex">
      <img v-show="place <= 3" src="../assets/icons/trophy.svg" alt="Pokal">
      <div class="points">{{ points }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreCard",
  props: {
    name: String,
    place: Number,
    points: String
  }
}
</script>

<style scoped>
.cardInternal {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 88%;
  margin: 15px auto 0 auto;
  max-width: 350px;
  min-width: 320px;
  padding: 10px;
}
.innerFlex{
  display: flex;
}
.name, .place {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 0.15rem;
}

.place {
  color: var(--red);

  margin-left: 10px;
}

.name {
  color: var(--blue);

  margin-left: 10px;
}

img {
  height: 1.3rem;
  margin-top: 0.15rem;

  margin-left: 10px;
}

.points {
  color: var(--yellow);
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 5px;
}

</style>