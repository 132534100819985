<template>
  <div>
    <Header page-text="Quellen"></Header>
    <div v-if="user">
      <div class="card cardInternal">
        <div class="teamName">Autoren</div><br>
        <div class="authors">Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        <div class="authors">Icons made by <a href="https://www.flaticon.com/authors/ultimatearm" title="ultimatearm">ultimatearm</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        <br>
        <div class="authors">Diese Website wurde erstellt durch: <a href="mailto:robinbrockhaus8@gmail.com">Robin Brockhaus</a> </div><br>
        <div class="button blue" v-on:click="$router.back()">Zurück</div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import {Settings} from 'luxon';

Settings.defaultLocale = 'de'

export default {
  name: "Attribution",
  components: {Header},
  data() {
    return {
      user: null,
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }
    });
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;
  width: 90%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
}

.teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.authors {
  margin-top: 5px;
  text-align: left;
  font-size: 0.8rem;
  color: black;
}

img {
  margin-top: 5px;
  width: 100%;
  height: auto;
}
.dataMedia a {
  margin-top: 8px;
  font-size: 1rem;
  color:var(--blue);
}

.button{
  margin-top: 10px;
  padding: 10px 15px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.blue{
   background-color: var(--blue);
 }

.webAuthor{
  margin: 20px 0;
}
</style>