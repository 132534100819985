<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}

</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --gray:#252525;
  --ltgray: #ababab;
  --yellow: #EBDE31;
  --blue: #2043B5;
  --red: #eb3131;
}

body{
  font-size: 10px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.card{
  width: 95%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
}


/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--gray);
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 3.5s;
  animation: fadein 0.5s, fadeout 0.5s 3.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }

}
input[type="password"], input[type="text"], input[type="number"], textarea,select{
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 50px;
  border: 1px solid var(--ltgray);
  padding: 0 15px;
  border-radius: 10px;
  resize: vertical;
}
textarea{
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 80px;
 }
.errorText {
  margin-top: 10px;
  color: red;
  font-size: 0.9rem;
}
</style>
