<template>
  <div>
    <label :for="id">{{ label }}</label>
    <input :type="type" :placeholder="placeholder" :name="name" :id="id" :value="value">
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: {
    type: String,
    placeholder: String,
    name: String,
    id: String,
    label: String,
    value:String,
  },
}
</script>

<style scoped>
</style>