<template>
  <div>
    <Header page-text="Dein Score"/>
    <div v-if="scoreList && user">
      <div v-if="scoreList.length > 0 && gesPoints">
        <div id="topCard" class="card"><span id="gesPoints">{{gesPoints.points}}</span><div id="bestPoints">Bestätigte Punkte</div></div>
        <BattleCard v-for="(score, index) in scoreList" :key="index" :name="score.name"
                    :members="score.validationState === '0'?'Wartet':score.validationState === '1'?'Akzeptiert':'Abgelehnt'"
                    :state="score.value.concat(score.type === 'Strecke'?' KM':score.type === 'Sekunden'?' Sek':' Wdh.')"
                    state-color="blue"
                    :teilnehmer-color="score.validationState === '0'?'yellow':score.validationState === '1'?'blue':'red'"
                    state-text="Eingabe" teilnehmer-text="Bestätigung"
                    :info="score.points+'P'" description=""></BattleCard>

      </div>
    </div>
    <div v-if="!scoreList" id="noData" class="card">
      Du hast noch keine Daten eingereicht!
    </div>
  </div>
</template>

<script>
import BattleCard from "@/components/BattleCard";
import Header from "@/components/Header";

export default {
  name: "OwnScoreBoard",
  components: {Header, BattleCard},
  data() {
    return {
      user: null,
      scoreList: null,
      gesPoints: null,
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }

      let formData = new FormData();
      formData.append('bid', this.$route.params.bid);
      formData.append("jwt", this.$getCookie('jwt'))
      this.$http.post(this.$api + "user/get_mystats.php", formData, {},).then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          //console.log(res.data.data);
          this.scoreList = res.data.data;
        }
      })

      formData = new FormData();
      formData.append('bid', this.$route.params.bid);
      formData.append("jwt", this.$getCookie('jwt'))
      this.$http.post(this.$api + "user/get_myBattlePoints.php", formData, {},).then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          //console.log(res.data.data);
          this.gesPoints = res.data.data;
        }
      })

    });
  }
}
</script>

<style scoped>
#topCard {
  padding: 20px;
  width: 50%;
  max-width: 180px;
  margin: 20px auto 0 auto;
  border: 2px solid var(--yellow);
}
#gesPoints{
  font-size: 2.2rem;
  font-weight: bold;
  color:var(--yellow);
}
#bestPoints{
  font-size: 0.6rem;
  color:var(--gray);
}

#noData {
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 80%;
  color: var(--gray);
  padding: 15px 25px;
  max-width: 400px;
  min-width: 270px;
}
</style>