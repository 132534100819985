<template>
  <div class="card cardInternal">
    <div class="left">
      <div class="name">Hallo {{ fistName }}</div>
      <div class="flexC">
        <div class="description">
          <div>Sport: <div class="values">{{ sport }}</div></div>

          <div class="marginT">Mannschaft{{ team.split(',').length > 1?'en':''}}:<div class="values">{{ team }}</div></div>
          <div class="marginT" v-show="!trainer">Trainer/innen:<div class="values" v-show="!trainer">{{ trainers }}</div></div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="stateCard">{{ trainer ? 'Trainer/in' : 'Sportler/in' }}</div>
      <div class="gesPoints">{{points}}</div>
      <div class="minPoints">Punkte</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationCard",
  props: {
    fistName: String,
    sport: String,
    team: String,
    trainers: String,
    points: String,
    trainer: Boolean
  }
}
</script>

<style scoped>
.cardInternal {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 90%;
  margin: 20px auto 0 auto;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
}
.left{
  width: 65%;
 }
.flexC{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 5px;
  line-height: 1.2rem;
}
.name{
  font-size: 1.2rem;
  text-align: left;
  font-weight: bold;
  color: black;
}
.marginT{
  margin-top: 5px;
}
.description, .values{
  text-align: left;
  font-size: 0.8rem;
}
.values{
  font-weight: bold;
  color: var(--blue);
}
.stateCard{
  background-color: var(--blue);
  border-radius: 5px;
  padding: 5px 15px;
  color:white;
  font-size: 0.8rem;
}
.gesPoints{
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--yellow);
  margin-top: 25px;
}
.minPoints{
  color: var(--ltgray);
  font-size: 0.6rem;
}
</style>