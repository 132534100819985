<template>
  <div>
    <Header page-text="Challenge"></Header>
    <div v-if="user">
      <div v-if="overlay === false">
        <div class="addButton blue"
             v-on:click="$router.push({name:'score', params: { teamid: $route.params.teamid, bid: $route.params.bid }})">
          Ranking
        </div>
        <div class="addButton red" style="margin-left:10px;" v-on:click="overlay = true">Hinzufügen</div>
        <TextCard v-for="challenge in challenges" :key="challenge.cid" :name="challenge.name"
                  :info="getEpocheString(challenge.date)" v-on:click.native="overlay = true; openUpdate(challenge);"></TextCard>
<!--        <div v-if="user.role === 'trainer'" style="margin-top: 40px"></div>-->
      </div>

      <div class="card cardInternal" v-if="overlay === true && !selectedUpdate">
        <CloseButton v-on:click.native="overlay = false" color="var(--gray)"></CloseButton>
        <div class="teamName">Challenge hinzufügen</div>
        <form @submit="checkForm">
          <InputText name="name" id="name" label="" type="text" placeholder="Name"></InputText>
          <div class="label">Beschreibung</div>
          <label>
            <textarea id="description" name="description" rows="4"/>
          </label>
          <div class="label">Datum</div>
          <datetime v-model="dateStart" input-id="startDate" :min-datetime="new Date().toISOString()"></datetime>
          <div class="label">Kategorie</div>
          <select id="category" name="category">
            <option value="running">Laufen</option>
            <option value="cycling">Radfahren</option>
            <option value="fitness">Fitness</option>
            <option value="power">Kraft</option>
            <option value="yoga">Yoga</option>
          </select>
          <div class="label">Daten</div>
          <select id="dataSet" name="dataSet">
            <option value="Strecke">Strecke in KM</option>
            <option value="Wiederholungen">Wiederholungen</option>
            <option value="Sekunden">Sekunden</option>
          </select>
          <InputText name="points" id="points" label="" type="number"
                     placeholder="Punkte pro 1KM/1 WDH/15 Sek"></InputText>
          <InputText name="pointsMax" id="pointsMax" label="" type="number"
                     placeholder="Maximale Punktzahl"></InputText>
          <div class="errorText" v-if="(error!=='')">{{ error }}</div>
          <InputSubmit value="Hinzufügen"/>
        </form>
      </div>
    </div>


    <!--Update-->
    <div class="card cardInternal" v-if="overlay === true && selectedUpdate">
      <CloseButton v-on:click.native="overlay = false; selectedUpdate = null;" color="var(--gray)"></CloseButton>
      <div class="teamName">Challenge ändern</div>
      <form @submit="checkFormUpdate">
        <InputText name="name" id="nameUpdate" label="" type="text" placeholder="Name" :value="selectedUpdate.name"></InputText>
        <div class="label">Beschreibung</div>
        <label>
          <textarea id="descriptionUpdate" name="description" rows="4" :value="selectedUpdate.description"/>
        </label>
        <div class="label">Datum</div>
        <datetime v-model="dateUpdate" input-id="startDate"></datetime>
        <div class="label">Kategorie</div>
        <select id="categoryUpdate" v-model="categoryUpdate" name="category">
          <option value="running">Laufen</option>
          <option value="cycling">Radfahren</option>
          <option value="fitness">Fitness</option>
          <option value="power">Kraft</option>
          <option value="yoga">Yoga</option>
        </select>
        <div class="label">Daten</div>
        <select id="dataSetUpdate" name="dataSet" v-model="dataSetUpdate">
          <option value="Strecke">Strecke in KM</option>
          <option value="Wiederholungen">Wiederholungen</option>
          <option value="Sekunden">Sekunden</option>
        </select>
        <InputText name="points" id="pointsUpdate" label="" type="number"
                   placeholder="Punkte pro 1KM/1 WDH/15 Sek" :value="selectedUpdate.points"></InputText>
        <InputText name="pointsMax" id="pointsMaxUpdate" label="" type="number"
                   placeholder="Maximale Punktzahl" :value="selectedUpdate.maxPoints"></InputText>
        <div class="errorText" v-if="(error!=='')">{{ error }}</div>
        <InputSubmit value="Ändern"/>
      </form>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import TextCard from "@/components/TextCard";
import InputSubmit from "@/components/InputSubmit";
import InputText from "@/components/InputText";
import {Settings} from 'luxon';
import CloseButton from "@/components/CloseButton";

Settings.defaultLocale = 'de'

export default {
  name: "Challenges",
  components: {CloseButton, InputText, InputSubmit, TextCard, Header},
  data() {
    return {
      dateStart: null,
      dateUpdate: null,
      user: null,
      error: "",
      challenges: null,
      overlay: false,
      selectedUpdate:null,
      categoryUpdate:'running',
      dataSetUpdate:'Strecke',
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.error = "";

      let name = document.getElementById("name").value;
      let description = document.getElementById("description").value;
      let points = document.getElementById("points").value;
      let maxPoints = document.getElementById("pointsMax").value;
      let category = document.getElementById("category").value;
      let dataSet = document.getElementById("dataSet").value;
      if (name === "") {
        this.error = "Bitte Namen eingeben!";
      } else if (description === "") {
        this.error = "Bitte Beschreibung eingeben!";
      } else if (points === "") {
        this.error = "Bitte Punkte eingeben!";
      } else if (!this.$isNumeric(points)) {
        this.error = "Die Punkte müssen eine Zahl sein!";
      } else if (maxPoints === "") {
        this.error = "Bitte Maximal Punkte eingeben!";
      } else if (!this.$isNumeric(maxPoints)) {
        this.error = "Die Maximal Punkte müssen eine Zahl sein!";
      } else if (this.dateStart == null || this.dateStart === "") {
        this.error = "Bitte Datum angeben!";
      } else {
        const formData = new FormData();
        formData.append('tid', this.$route.params.teamid);
        formData.append('bid', this.$route.params.bid);
        formData.append("jwt", this.$getCookie('jwt'));
        formData.append("name", name);
        formData.append("description", description);
        formData.append("type", dataSet);
        formData.append("points", points);
        formData.append("maxPoints", maxPoints);
        formData.append("category", category);
        formData.append("date", Math.round((new Date(this.dateStart)).getTime() / 1000).toString());

        this.$http.post(this.$api + "user/create_challenge.php", formData, {}).then((res) => {
          //console.log(res.data);
          if (res.data.success) {
            this.$snackbar(res.data.data);
            this.getChallenges();
            this.overlay = false;
          } else {
            this.error = res.data.error;
          }
        })
      }
    },
    checkFormUpdate: function (e) {
      e.preventDefault();
      this.error = "";

      let name = document.getElementById("nameUpdate").value;
      let description = document.getElementById("descriptionUpdate").value;
      let points = document.getElementById("pointsUpdate").value;
      let maxPoints = document.getElementById("pointsMaxUpdate").value;
      if (name === "") {
        this.error = "Bitte Namen eingeben!";
      } else if (description === "") {
        this.error = "Bitte Beschreibung eingeben!";
      } else if (points === "") {
        this.error = "Bitte Punkte eingeben!";
      } else if (!this.$isNumeric(points)) {
        this.error = "Die Punkte müssen eine Zahl sein!";
      } else if (maxPoints === "") {
        this.error = "Bitte Maximal Punkte eingeben!";
      } else if (!this.$isNumeric(maxPoints)) {
        this.error = "Die Maximal Punkte müssen eine Zahl sein!";
      } else if (this.dateUpdate == null || this.dateUpdate === "") {
        this.error = "Bitte Datum angeben!";
      } else {
        const formData = new FormData();
        formData.append('tid', this.$route.params.teamid);
        formData.append('bid', this.$route.params.bid);
        formData.append('cid', this.selectedUpdate.cid);
        formData.append("jwt", this.$getCookie('jwt'));
        formData.append("name", name);
        formData.append("description", description);
        formData.append("type", this.dataSetUpdate);
        formData.append("points", points);
        formData.append("maxPoints", maxPoints);
        formData.append("category", this.categoryUpdate);
        formData.append("date", Math.round((new Date(this.dateUpdate)).getTime() / 1000).toString());

        this.$http.post(this.$api + "user/update_challenge.php", formData, {}).then((res) => {
          //console.log(res.data);
          if (res.data.success) {
            this.$snackbar(res.data.data);
            this.getChallenges();
            this.overlay = false;
          } else {
            this.error = res.data.error;
          }
        })
      }
    },
    openUpdate:function(challenge){
      this.selectedUpdate = challenge;
      this.dateUpdate = new Date(this.selectedUpdate.date*1000).toISOString();
      this.categoryUpdate = challenge.category.toString();
      this.dataSetUpdate = challenge.type.toString();
    },
    getEpocheString: function (start) {
      //var ts = Math.round((new Date()).getTime() / 1000);
      //console.log(ts);
      let startD = new Date()
      startD.setTime(start * 1000);
      return startD.getDate() + "." + (startD.getMonth() + 1) + "." + startD.getFullYear();
    },
    getChallenges: function () {
      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))
      formData.append("tid", this.$route.params.teamid)
      formData.append("bid", this.$route.params.bid)
      this.$http.post(this.$api + "user/get_challenges.php", formData, {},).then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          this.challenges = res.data.data;
        } else {
          this.$snackbar("Etwas ist schief gegangen!");
        }
      });
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      } else if (this.user.role !== 'trainer') {
        this.$router.push({name: "home"});
        return;
      }
      this.getChallenges();
    });
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;
  width: 90%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
}

.addButton {
  margin-top: 20px;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

form .label {
  text-align: center;
  margin-top: 14px;
  margin-bottom: -10px;
  font-size: 1rem;
}

.red {
  background-color: var(--red);
}

.blue {
  background-color: var(--blue);
}
</style>