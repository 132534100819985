<template>
  <div class="card cardInternal">
    <div class="headContent">
      <div class="name">{{ name }}</div>
      <div class="additionalInfo">{{ info }}</div>
    </div>
    <div v-if="description !== ''">
      <div id="descriptionD">
        <div id="descriptionHeadline">Beschreibung:</div>
        <div id="description">{{ description }}</div>
      </div>
    </div>
    <div class="stateInfo">
      <div class="stateSize">
        <div class="stateHeadline">{{ teilnehmerText }}</div>
        <div :class="'stateCard '+ teilnehmerColor">{{ members }}</div>
      </div>
      <div class="stateSize" style="margin-left: 20px;">
        <div class="stateHeadline">{{ stateText }}</div>
        <div :class="'stateCard '+ stateColor">{{ state }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BattleCard",
  props: {
    name: String,
    info: String,
    state: String,
    members: String,
    description: String,
    stateText:String,
    teilnehmerText:String,
    stateColor:String,
    teilnehmerColor:String,
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;

  width: 88%;
  margin: 15px auto 0 auto;
  max-width: 350px;
  min-width: 320px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all .3s;
}

.cardInternal:hover {
  transform: scale(1.1);
}

.headContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.name, .additionalInfo {
  font-size: 1.2rem;
  font-weight: bold;
}

.name {
  color: var(--blue);
}

.additionalInfo {
  color: var(--yellow);
}

.stateInfo {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.stateHeadline {
  font-size: 0.8rem;
  color: var(--gray);
}

.stateCard {
  margin-top: 5px;
  padding: 7px 13px;
  border-radius: 15px;
  color: white;
}

.stateSize {
  width: 35%;
}

.red {
  background-color: var(--red);
}

.blue {
  background-color: var(--blue);
}
.yellow {
  background-color: var(--yellow);
}

#descriptionD {
  margin-top: 20px;
  text-align: left;
}

#descriptionHeadline {
  color: black;
  font-size: 0.8rem;
  font-weight: bold;
}

#description {
  font-size: 0.8rem;
}

#description {
  margin-top: 5px;
  color: var(--ltgray);
}
</style>