<template>
  <div class="card cardInternal">
    <img :src="require('../assets/'+src)" :alt="alt">
    <div class="headline">{{headline}}</div>
    <div class="middleText">{{middleText}}</div>
    <div class="bottomText cText">{{bottomText}}</div>
  </div>
</template>

<script>
export default {
  name: "BigCard",
  props:{
    src: String,
    alt: String,
    headline: String,
    middleText: String,
    bottomText: String,
  }
}
</script>

<style scoped>
.cardInternal{
  width: 75%;
  margin: 20px auto 0 auto;
  max-width: 300px;
  min-width: 270px;
  padding: 25px;
}
img{
  width: 35%;
}
.headline{
  color: var(--blue);
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 20px;
}
.middleText{
  color: var(--red);
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
}
.bottomText{
  color: var(--yellow);
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 20px;
}
</style>