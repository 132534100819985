<template>
  <div class="card cardInternal" :style="'color:'+color+';'">
      <div class="name">{{ name }}</div>
      <div class="arrow">&#10132;</div>
  </div>
</template>

<script>
export default {
  name: "NavigationCard",
  props: {
    name: String,
    color: String,
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 88%;
  margin: 15px auto 0 auto;
  max-width: 350px;
  min-width: 320px;
  padding: 15px 25px;
  cursor: pointer;
  transition: all .3s;
}
.cardInternal:hover {
  transform: scale(1.1);
}

.name{
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}
.arrow{
  position: absolute;
  right: 25px;
  top:2px;
  font-size: 2rem;
}

</style>