<template>
  <div>
    <Header page-text="Mannschafts Score"/>

    <div class="card cardInternal" v-if="user && team">
      <div id="teamName">Sportler/in wählen</div>
      <select v-model="selectedUser" style="margin-top: 10px" @change="getStats();getBattlePoints();scoreList = null;">
        <option v-for="(userM, index) in team.athletes" :key="index" :value="userM.uid">
          {{ userM.firstName + ' ' + userM.lastName }}
        </option>
      </select>
    </div>

    <div v-if="scoreList && user">
      <div v-if="scoreList.length > 0 && gesPoints">
        <div id="topCard" class="card"><span id="gesPoints">{{ gesPoints.points }}</span>
          <div id="bestPoints">Bestätigte Punkte</div>
        </div>
        <BattleCard v-for="(score, index) in scoreList" :key="index" :name="score.name"
                    :members="score.validationState === '0'?'Wartet':score.validationState === '1'?'Akzeptiert':'Abgelehnt'"
                    :state="score.value.concat(score.type === 'Strecke'?' KM':score.type === 'Sekunden'?' Sek':' Wdh.')"
                    state-color="blue"
                    :teilnehmer-color="score.validationState === '0'?'yellow':score.validationState === '1'?'blue':'red'"
                    state-text="Eingabe" teilnehmer-text="Bestätigung"
                    :info="score.points+'P'" description=""></BattleCard>

      </div>
    </div>
    <div v-if="!scoreList" id="noData" class="card">
      Du hast noch keine Daten eingereicht!
    </div>
  </div>
</template>

<script>
import BattleCard from "@/components/BattleCard";
import Header from "@/components/Header";

export default {
  name: "TrainerScoreBoard",
  components: {Header, BattleCard},
  data() {
    return {
      user: null,
      scoreList: null,
      gesPoints: null,
      team: null,
      selectedUser: null,
    }
  },
  methods: {
    getStats: function () {
      if (this.selectedUser != null) {
        let formData = new FormData();
        formData.append('bid', this.$route.params.bid);
        formData.append('tid', this.$route.params.teamid);
        formData.append('atUid', this.selectedUser);
        formData.append("jwt", this.$getCookie('jwt'))
        this.$http.post(this.$api + "user/get_teamStats.php", formData, {},).then((res) => {
          //console.log(res.data);
          if (res.data.success) {
            //console.log(res.data.data);
            this.scoreList = res.data.data;
          }
        })
      }
    },
    getBattlePoints: function () {
      let formData = new FormData();
      formData.append('bid', this.$route.params.bid);
      formData.append('tid', this.$route.params.teamid);
      formData.append('atUid', this.selectedUser);
      formData.append("jwt", this.$getCookie('jwt'))
      this.$http.post(this.$api + "user/get_teamBattlePoints.php", formData, {},).then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          //console.log(res.data.data);
          this.gesPoints = res.data.data;
        }
      })
    },
    getTeamMembers: function () {
      if (this.user.role === 'trainer') {
        const formData = new FormData();
        formData.append("jwt", this.$getCookie('jwt'))
        formData.append("tid", this.$route.params.teamid)
        this.$http.post(this.$api + "user/get_complete_team.php", formData, {},).then((res) => {
          if (res.data.success) {
            this.team = res.data.data;
          }
        })
      }
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      } else if (this.user.role !== 'trainer') {
        this.$router.push({name: "home"});
        return;
      }
      if (this.user.role === 'trainer') {
        this.getTeamMembers();
      }

    });
  }
}
</script>

<style scoped>
.cardInternal {
  width: 85%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
  position: relative;
}

#topCard {
  padding: 20px;
  width: 50%;
  max-width: 180px;
  margin: 20px auto 0 auto;
  border: 2px solid var(--yellow);
}

#gesPoints {
  font-size: 2.2rem;
  font-weight: bold;
  color: var(--yellow);
}

#bestPoints {
  font-size: 0.6rem;
  color: var(--gray);
}

#noData {
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 80%;
  color: var(--gray);
  padding: 15px 25px;
  max-width: 400px;
  min-width: 270px;
}
#teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}
</style>