<template>
  <div id="centerHV">
    <div id="login" class="card">
      <img src="../assets/logo.png" width="100px" alt="1. Paderborner Schwimmverein" id="psv_logo">
      <form @submit="checkForm" id="loginForm">
        <InputText type="text" placeholder="Benutzername" name="username" id="username" label=""></InputText>
        <InputText type="password" placeholder="Passwort" name="password" id="password" label=""></InputText>
        <div class="errorText" v-if="(error!=='')">{{ error }}</div>
        <InputSubmit value="Einloggen"/>
      </form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/InputText";
import InputSubmit from "@/components/InputSubmit";

export default {
  name: "Login",
  components: {InputSubmit, InputText},
  data() {
    return {
      error: "",
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.error = "";
      let username = document.getElementById("username").value;
      let password = document.getElementById("password").value;
      if (username === "") {
        this.error = 'Bitte Nutzername eingeben!';
      } else if (password === "") {
        this.error = 'Bitte Passwort eingeben!';
      } else {
        const formData = new FormData(document.getElementById('loginForm'));
        this.$http.post(this.$api + "user/login.php", formData, {}).then((res) => {
          //console.log(res.data);
          if (!res.data.success) {
            this.error = res.data.error;
          } else {
            this.$setCookie("jwt", res.data.data, 12);
            this.$router.push({name: 'home'});
          }
        })
      }
    }
  }, created() {
    if (this.$getCookie("jwt") !== "") {
      this.$getUser().then(result => {
            this.user = result
            if (this.user !== null) {
              this.$router.push({name: "home"});
            }
          }
      );
    }
  }
}
</script>

<style scoped>
#centerHV {
  width: 100%;
  height: 100vh;
  background-color: white;
}

#login {
  width: 95%;
  max-width: 400px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#login form {
  margin-top: 60px;
}

#psv_logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: -50px;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}


</style>