<template>
  <div style="margin-bottom: 20px">
    <Header page-text="Start"></Header>
    <div v-if="overlay === false">
      <InformationCard v-if="user && teams && trainerList !== ''" :fist-name="user.firstName" :sport="teamsList.sport"
                       :team="teamsList.teams"
                       :trainers="trainerList" :points="points" :trainer="user.role ==='trainer'"/>
      <div v-if="user">

        <div v-if="user.role === 'trainer'" style="margin-top: 40px">
          <div class="noData card">
            Trainer/innen
          </div>
          <NavigationCard class="navCards" name="Battles"
                          v-on:click.native="$router.push({name:'battle', params:{teamid:teams[0].tid}})"
                          color="var(--red)"></NavigationCard>
          <NavigationCard class="navCards" name="Bestätigen"
                          v-on:click.native="$router.push({name:'verify'})"
                          color="var(--red)"></NavigationCard>
          <NavigationCard class="navCards" name="Mannschaft"
                          v-on:click.native="teams.length > 1?overlay=true:$router.push('team/'+teams[0].tid)"
                          color="var(--red)"></NavigationCard>
          <a class="refTut"
             :href="$api+'user/get_video.php?jwt='+$getCookie('jwt')+'&filePath=../_file_uploads/trainer.mkv'">
            <NavigationCard class="navCards" name="Anleitung"
                            color="var(--red)"></NavigationCard>
          </a>
        </div>
        <div v-if="user.role === 'athlete'" style="margin-top: 40px">
          <a class="refTut"
             :href="$api+'user/get_video.php?jwt='+$getCookie('jwt')+'&filePath=../_file_uploads/athlete.mkv'">
            <NavigationCard class="navCards" name="Anleitung"
                            color="var(--red)"></NavigationCard>
          </a>
        </div>
        <!--      <div id="phCard">Battles</div>-->
        <div v-if="user.role === 'athlete' || user.role === 'trainer'" style="margin-top: 40px">
          <div class="noData card">
            Battles
          </div>
          <BattleCard v-for="(battle, index) in battles" :key="battle.bid" :name="battle.name"
                      :members="battlesAdditional[index].members" :state="battlesAdditional[index].state" state-color="red" teilnehmer-color="blue" state-text="Status" teilnehmer-text="Teilnehmer"
                      :info="getEpocheString(battle.start,battle.end)" :description="battle.description"
                      v-on:click.native="$router.push({name:'challenge', params: { teamid: teams[0].tid, bid: battle.bid }})"></BattleCard>
        </div>
      </div>
    </div>
    <div class="card cardInternal" v-if="teams && overlay === true">
      <CloseButton v-on:click.native="overlay = false;" color="var(--gray)"></CloseButton>
      <select v-model="selectedTeam" style="margin-top: 40px">
        <option v-for="(team, index) in teams" :key="index" :value="team">{{ team.name }}</option>
      </select>
      <NavigationCard class="navCards" name="Weiter"
                      v-on:click.native="selectedTeam === null?$snackbar('Bitte Mannschaft auswählen!'):$router.push('team/'+selectedTeam.tid)" color="var(--red)"></NavigationCard>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import InformationCard from "@/components/InformationCard";
import NavigationCard from "@/components/NavigationCard";
import BattleCard from "@/components/BattleCard";
import CloseButton from "@/components/CloseButton";

export default {
  name: "Home",
  components: {CloseButton, BattleCard, NavigationCard, InformationCard, Header},
  data() {
    return {
      user: null,
      trainerList: "",
      teamsList: "",
      teams: null,
      battles: null,
      battlesAdditional: null,
      points: "0",
      selectedTeam: null,
      overlay: false,
    }
  },
  methods: {
    getEpocheString: function (start, end) {
      let startD = new Date()
      let endD = new Date()
      startD.setTime(parseInt(start) * 1000);
      endD.setTime(parseInt(end) * 1000);
      return startD.getDate() + "." + (startD.getMonth() + 1) + " - " + endD.getDate() + "." + (endD.getMonth() + 1) + "." + endD.getFullYear();
    },
    getBattles: function () {
      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))
      formData.append("tid", this.teams[0].tid.toString())
      this.$http.post(this.$api + "user/get_battles.php", formData, {},).then((res) => {
            if (res.data.success) {
              this.battles = res.data.data;
              this.battlesAdditional = [];
              for (let i = 0; i < this.battles.length; i++) {
                let members = 'Mannschaft';
                let state = 'Demnächst';
                // console.log(this.battles[i])
                if (this.battles[i].glob === "1") {
                  members = 'Alle';
                }
                // console.log(Date.now())
                // console.log((parseInt(this.battles[i].start) * 1000));
                // console.log((parseInt(this.battles[i].end) * 1000));
                if (Date.now() >= (parseInt(this.battles[i].start) * 1000) && Date.now() <= (parseInt(this.battles[i].end) * 1000)) {
                  state = 'Gestarted';
                } else if ((parseInt(this.battles[i].end) * 1000) < Date.now()) {
                  state = 'Beendet';
                }
                this.battlesAdditional.push({"state": state, "members": members});
              }
            } else {
              this.$snackbar("Etwas ist schief gegangen!");
            }
          }
      )
      ;
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }
      if (this.user.role !== 'trainer') {
        let formData = new FormData();
        formData.append("jwt", this.$getCookie('jwt'))
        this.$http.post(this.$api + "user/get_trainer.php", formData, {},).then((res) => {
          if (res.data.success) {
            // console.log(res.data.data);
            this.trainerList = "";
            for (let i = 0; i < res.data.data.length; i++) {
              let trainer = res.data.data[i].firstName.concat(" ");
              trainer = trainer.concat(res.data.data[i].lastName);
              if (this.trainerList.indexOf(trainer) === -1) {
                this.trainerList = this.trainerList.concat(trainer.concat(", "));
              }
            }
            // console.log(this.trainerList);
            this.trainerList = this.trainerList.substring(0, this.trainerList.length - 2)
          }
        })

      } else {
        this.trainerList = '+';
      }
      const formDataS = new FormData();
      formDataS.append("jwt", this.$getCookie('jwt'))
      this.$http.post(this.$api + "user/get_points.php", formDataS, {},).then((res) => {
        if (res.data.success) {
          this.points = res.data.data;
          if (this.points === null) {
            this.points = '0';
          }
        } else {
          this.points = '0';
        }
      })

      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))

      this.$http.post(this.$api + "user/get_teams.php", formData, {},).then((res) => {
        if (res.data.success) {
          this.teams = res.data.data;
          let sportList = "";
          let teamsList = "";
          for (let i = 0; i < res.data.data.length; i++) {
            let sport = res.data.data[i].sport;
            // console.log(sportList.indexOf(sport));
            if (sportList.indexOf(sport) === -1) {
              sportList = sportList.concat(sport.concat(", "));
            }
            teamsList = teamsList.concat(res.data.data[i].name.concat((i !== res.data.data.length - 1) ? ", " : ""));
          }
          // console.log(sportList.substring(0, sportList.length - 2));
          // console.log(teamsList);
          this.teamsList = {"teams": teamsList, "sport": sportList.substring(0, sportList.length - 2)};
          if (this.user.role === 'athlete' || this.user.role === 'trainer') {
            this.getBattles();
          }

        }
      })
    });
  }
}
</script>

<style scoped>
.cardInternal {
  width: 85%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
  position: relative;
}

#phCard {
  background-color: red;
  display: block;
  margin-top: 20px;
}

.noData {
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 95%;
  max-width: 400px;
  min-width: 270px;
  color: var(--gray);
  padding: 15px 25px;
}

.refTut {
  text-decoration: none;
}
</style>