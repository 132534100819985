<template>
  <div>
    <Header page-text="Bestätigen"></Header>
    <div v-if="user && stat">
      <div class="card cardInternal">
        <div class="teamName">Bestätigen</div>
        <div class="dataHeadline">Challenge: <span class="dataInline">{{ stat.name }}</span></div>
        <div class="dataHeadline">Max Punkte: <span class="dataInline">{{ stat.maxPoints }}</span></div>
        <div class="dataHeadline" style="margin-top: 15px">Eingaben:</div>
        <div class="dataHeadline">Namen: <span class="dataInline">{{ stat.firstName }} {{ stat.lastName }}</span></div>
        <div class="dataHeadline">Benutzername: <span class="dataInline">{{ stat.username }}</span></div>
        <div class="dataHeadline">{{ stat.type }}: <input :placeholder="stat.type" type="number" step=".1" id="anz"
                                                          name="anz" :value="stat.value" v-on:keyup="getPoints"></div>
        <div class="dataHeadline">Punkte: <span class="dataInline">{{ stat.points }}</span></div>
        <div class="dataHeadline">Kommentar: <span class="dataInline">{{ stat.comment }}</span></div>
        <div class="dataMedia" v-for="(filePath, index) in JSON.parse(stat.filePath)" :key="index">
          <img
              v-if="filePath.indexOf('jpg') !== -1 || filePath.indexOf('png') !== -1 || filePath.indexOf('jpeg') !== -1 || filePath.indexOf('gif') !== -1"
              :src="$api+'user/get_image.php?jwt='+$getCookie('jwt')+'&filePath='+filePath" alt="">
          <a v-if="filePath.indexOf('mp4') !== -1 || filePath.indexOf('mov') !== -1 || filePath.indexOf('wmv') !== -1 || filePath.indexOf('avi') !== -1 || filePath.indexOf('flv') !== -1 || filePath.indexOf('f4v') !== -1 || filePath.indexOf('mkv') !== -1"
             :href="$api+'user/get_video.php?jwt='+$getCookie('jwt')+'&filePath='+filePath">Video</a>
        </div>
        <div class="button red" v-on:click="setStat(2,stat.sid, stat.filePath)">Ablehnen</div>
        <div class="button blue" v-on:click="setStat(1,stat.sid, stat.filePath)">Akzeptieren</div>
      </div>
    </div>
    <div v-if="!stat" id="noData" class="card">
      Es sind grade keine Daten, welche Bestätigt werden müssen vorhanden.
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import {Settings} from 'luxon';

Settings.defaultLocale = 'de'

export default {
  name: "Verify",
  components: {Header},
  data() {
    return {
      user: null,
      stat: null
    }
  },
  methods: {
    getStat: function () {
      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))
      this.$http.post(this.$api + "user/get_stat.php", formData, {},).then((res) => {
        if (res.data.success) {
          this.stat = res.data.data;
          this.points = this.stat.points;
          this.enterdVal = this.stat.value;
          //console.log(this.stat)
        } else {
          this.$snackbar("Es sind keine Daten zum Bestätigen vorhanden!");
        }
      });
    },
    setStat: function (accept, sid, files) {
      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))
      formData.append("state", accept.toString())
      formData.append("sid", sid.toString())
      formData.append("files", files.toString())
      let anz = document.getElementById("anz").value;
      formData.append("value", anz);

      //max points calc
      let pointsM = (this.stat.cPoints * (anz / (this.stat.type === 'Sekunden' ? 15 : 1)));
      pointsM = pointsM > this.stat.maxPoints ? this.stat.maxPoints : pointsM;
      pointsM = Math.round(pointsM);
      formData.append("points", pointsM.toString());

      this.$http.post(this.$api + "user/set_stat.php", formData, {},).then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          this.stat = null;
          if (accept === 1) {
            this.$snackbar("Akzeptiert")
          } else if (accept === 2) {
            this.$snackbar("Abgelehnt")
          }
          this.getStat();
        } else {
          this.$snackbar("Es ist etwas schief gelaufen");
        }
      });
    },
    getPoints: function () {
      let anz = document.getElementById("anz").value;
      this.stat.value = anz;
      if (this.$isNumeric(anz)) {
        let pointsM = (this.stat.cPoints * (anz / (this.stat.type === 'Sekunden' ? 15 : 1)));
        pointsM = pointsM > this.stat.maxPoints ? this.stat.maxPoints : pointsM;
        this.stat.points = Math.round(pointsM);
      }
    },
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      } else if (this.user.role !== 'trainer') {
        this.$router.push({name: "home"});
        return;
      }
      this.getStat();
    });
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;
  width: 90%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
}

.teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.dataHeadline {
  margin-top: 5px;
  text-align: left;
  font-size: 1rem;
  color: black;
}

.dataInline {
  color: var(--blue);
  font-weight: bold;
  font-size: 1.2rem;
}

.dataMedia {
  margin-top: 10px;
}

img {
  margin-top: 5px;
  width: 100%;
  height: auto;
}

.dataMedia a {
  margin-top: 8px;
  font-size: 1rem;
  color: var(--blue);
}

.button {
  margin-top: 10px;
  padding: 10px 15px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.red {
  background-color: var(--red);
}

.blue {
  background-color: var(--blue);
}

#noData {
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 80%;
  color: var(--gray);
  padding: 15px 25px;
  max-width: 400px;
  min-width: 270px;
}
</style>