<template>
  <div>
    <Header page-text="Challenge"/>
    <div v-if="challenge && challengeAdditional">
      <div v-if="overlay === 0">
        <div class="card noData">
          Tages Challenge(s)
        </div>
        <BigCard v-for="(ch,index) in challenge" :key="index" id="dailyC" :src="'icons/'+challengeAdditional[index].img"
                 :alt="ch.name" :headline="ch.name"
                 :middle-text="challengeAdditional[index].points"
                 :bottom-text="countDown"
                 v-on:click.native="choosenChallenge = ch; choosenIndex = index; overlay = 1; "></BigCard>
      </div>
    </div>
    <div v-if="overlay === 0">
      <div class="card noData">
        Optionen
      </div>
      <NavigationCard name="Ranking" v-on:click.native="$router.push('score')"
                      style="margin-top: 20px"></NavigationCard>
      <NavigationCard name="Meine Daten" v-on:click.native="$router.push({name:'myscore'})"
                      style="margin-top: 10px"></NavigationCard>
      <NavigationCard name="Mannschaftsdaten"
                      v-on:click.native="teams.length > 1?openTeamChooseOverlay():$router.push({name:'allscore',params:{teamid:$route.params.teamid,bid: $route.params.bid}});"
                      style="margin-top: 10px"></NavigationCard>
    </div>
    <div v-if="challenge && challengeAdditional ">
      <div class="card cardInternal" v-if="overlay === 1">
        <CloseButton v-on:click.native="overlay = 0; points = 0;" color="var(--gray)"></CloseButton>
        <div id="challengeData">
          <img :src="require('../assets/icons/'+challengeAdditional[choosenIndex].img)" alt="challenge.name">
          <div id="challengeVal" style="margin-right: 30px">
            <div id="cName">{{ choosenChallenge.name }}</div>
            <div id="cAdditional">{{ challengeAdditional[choosenIndex].points }}</div>
            <div id="cTimer">{{ countDown }}</div>
          </div>
        </div>
        <div id="descriptionD">
          <div id="descriptionHeadline">Beschreibung:</div>
          <div id="description" v-html="choosenChallenge.description"></div>
        </div>
        <div id="doText">Mitmachen</div>
        <form @submit="checkForm">
          <div class="label">{{ choosenChallenge.type }} {{ choosenChallenge.type === 'Strecke' ? 'in KM' : '' }}</div>
          <input :placeholder="choosenChallenge.type" type="number" step=".1" id="anz" name="anz"
                 v-on:keyup="getPoints">
          <div class="label">Kommentar (freiwillig, Link zum Video mögl.)</div>
          <label>
            <textarea id="comment" name="comment" rows="4"/>
          </label>
          <div class="label">Beweis(e)</div>
          <div class="beweiseInfo" v-on:click="$router.push({name:'converter'})"
               style="margin-top: 15px;margin-bottom: -10px; text-decoration: underline; color: var(--blue); cursor: pointer;">
            Hilfe meine Videos sind zu groß
          </div>
          <label for="validationFile" id="fileUploadButton">Beweise hochladen (max. 500MB)</label>
          <input type="file" name="validationFile" id="validationFile" ref="files" hidden multiple
                 v-on:change="handleFileUploads"/>
          <div class="beweiseInfo red">Die Beweisdateien werden umgehend nach dem Bestätigen gelöscht. Außerdem können
            diese zum Bestätigen nur von Trainer/innen (sichtbar auf der Startseite) der Mannschaft eingesehen werden.
          </div>
          <div id="filesToUpload">
            <div class="fileList" v-for="(file, index) in files" :key="index">{{ file.name }} -
              {{ Math.floor(file.size / 1000000) + 'MB' }}
              <CloseButton style="top:0;" v-on:click.native="files.splice(index,1); error = '';"></CloseButton>
            </div>
          </div>
          <div class="maxPoints">Max Punktzahl: {{ choosenChallenge.maxPoints }}P</div>
          <div class="gesPoints">{{ points }}</div>
          <div class="minPoints">Punkte</div>

          <div class="errorText" v-if="(error!=='')">{{ error }}</div>
          <InputSubmit value="Senden"/>
          <div class="beweiseInfo">Achtung es kann nur einmal am Tag abgeben werden! (Außer, wenn eure Daten abgelehnt
            wurden.)
          </div>
        </form>
      </div>
      <div class="card cardInternal" v-if="overlay === 2">
        <div class="teamName">Hochladen</div>
        <progress max="100" :value.prop="uploadPercentage" style="margin-top: 10px; width: 80%"></progress>
      </div>

      <div class="card cardInternal" v-if="teams && overlay === 3">
        <CloseButton v-on:click.native="overlay = 0; " color="var(--gray)"></CloseButton>
        <select v-model="selectedTeam" style="margin-top: 40px">
          <option v-for="(team, index) in teams" :key="index" :value="team">{{ team.name }}</option>
        </select>
        <NavigationCard class="navCards" name="Weiter"
                        v-on:click.native="selectedTeam === null?$snackbar('Bitte Mannschaft auswählen!'):$router.push({name:'allscore',params:{teamid:selectedTeam.tid,bid: $route.params.bid}})"
                        color="var(--red)"></NavigationCard>
      </div>
    </div>
  </div>
</template>

<script>
import BigCard from "@/components/BigCard";
import Header from "@/components/Header";
import NavigationCard from "@/components/NavigationCard";
import CloseButton from "@/components/CloseButton";
import InputSubmit from "@/components/InputSubmit";


export default {
  name: "DailyChallenge",
  components: {InputSubmit, CloseButton, NavigationCard, Header, BigCard},
  data() {
    return {
      challenge: null,
      challengeAdditional: null,
      choosenChallenge: null,
      choosenIndex: null,
      overlay: 0,
      error: "",
      points: 0,
      files: [],
      uploadPercentage: 0,
      countDown: "",
      teams: null,
      selectedTeam: null,
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.error = "";

      let anz = document.getElementById("anz").value;
      let comment = document.getElementById("comment").value;
      if (anz === "") {
        this.error = "Bitte " + this.choosenChallenge.type + " eingeben";
      } else if (!this.$isNumeric(anz)) {
        this.error = this.choosenChallenge.type + " muss eine Zahl sein!";
      } else if (this.files.length === 0) {
        this.error = "Bitte mind. 1 Beweis hochladen";
      } else {
        const formData = new FormData();
        formData.append('tid', this.$route.params.teamid);
        formData.append('bid', this.$route.params.bid);
        formData.append("jwt", this.$getCookie('jwt'));
        formData.append("cid", this.choosenChallenge.cid);
        formData.append("comment", comment);
        formData.append("value", anz.toString());

        //max points calc
        let pointsM = (this.choosenChallenge.points * (anz / (this.choosenChallenge.type === 'Sekunden' ? 15 : 1)));
        pointsM = pointsM > this.choosenChallenge.maxPoints ? this.choosenChallenge.maxPoints : pointsM;
        pointsM = Math.round(pointsM);
        formData.append("points", pointsM.toString());

        let gesFilesize = 0;
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          gesFilesize += file.size / 1000000;
          formData.append('files[' + i + ']', file);
        }

        if (gesFilesize > 499.9) {
          this.error = "Alle Dateien zusammen dürfen max. 500MB groß sein!"
          return;
        }

        this.overlay = 2;

        this.$http.post(this.$api + "user/send_stats.php", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            if (this.uploadPercentage > 99) {
              //console.log("ready")
            }
          }.bind(this)
        }).then((res) => {
          if (res.data.success) {
            this.$snackbar(res.data.data);
            this.overlay = 0;
          } else {
            this.overlay = 1;
            this.error = res.data.error;
            this.$snackbar(res.data.error);
          }
          this.points = 0;
          this.files = [];
        })
      }
    },
    getDailyChallenge: function () {
      const formData = new FormData();
      formData.append("jwt", this.$getCookie('jwt'))
      formData.append("tid", this.$route.params.teamid)
      formData.append("bid", this.$route.params.bid)
      this.$http.post(this.$api + "user/get_dailyChallenge.php", formData, {},).then((res) => {
            //console.log(res.data);
            if (res.data.success) {
              if (res.data.data === false || res.data.data === "undefined" || res.data.data === null) {
                return;
              }
              this.challenge = res.data.data;
              this.challengeAdditional = [];
              for (let i = 0; i < this.challenge.length; i++) {
                this.challengeAdditional.push({
                  "img": this.challenge[i]['category'] + ".svg",
                  "points": this.challenge[i]['points'].concat(this.challenge[i]['type'] === "Strecke" ? "P pro KM" : this.challenge[i]['type'] === "Sekunden" ? "P pro 15 Sekunden" : "P pro Wdh.")
                });
              }
            } else {
              this.$snackbar("Etwas ist schief gegangen!");
            }
          }
      );
    },
    getPoints: function () {
      let anz = document.getElementById("anz").value;
      if (this.$isNumeric(anz)) {
        let pointsM = (this.choosenChallenge.points * (anz / (this.choosenChallenge.type === 'Sekunden' ? 15 : 1)));
        pointsM = pointsM > this.choosenChallenge.maxPoints ? this.choosenChallenge.maxPoints : pointsM;
        this.points = Math.round(pointsM);
      }
    },
    timer: function (fn, t) {
      let timerObj = setInterval(fn, t);

      this.stop = function () {
        if (timerObj) {
          clearInterval(timerObj);
          timerObj = null;
        }
        return this;
      }

      // start timer using current settings (if it's not already running)
      this.start = function () {
        if (!timerObj) {
          this.stop();
          timerObj = setInterval(fn, t);
        }
        return this;
      }

      // start with new or original interval, stop current interval
      this.reset = function (newT = t) {
        t = newT;
        return this.stop().start();
      }
    }
    ,
    handleFileUploads() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i]);
      }

      let filesGesSize = 0;

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let sFileName = file.name;
        let sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
        filesGesSize += file.size / 1000000;
        // let iFileSize = file.size;
        // let iConvert = (file.size / 1048576).toFixed(2);
        this.error = "";

        if (!(sFileExtension === "jpg") && !(sFileExtension === "JPG")
            && !(sFileExtension === "jpeg") && !(sFileExtension === "JPEG")
            && !(sFileExtension === "png") && !(sFileExtension === "PNG")
            && !(sFileExtension === "gif") && !(sFileExtension === "GIF")
            && !(sFileExtension === "mp4") && !(sFileExtension === "MP4")
            && !(sFileExtension === "m4v") && !(sFileExtension === "M4V")
            && !(sFileExtension === "mov") && !(sFileExtension === "MOV")
            && !(sFileExtension === "wmv") && !(sFileExtension === "WMV")
            && !(sFileExtension === "avi") && !(sFileExtension === "AVI")
            && !(sFileExtension === "flv") && !(sFileExtension === "flv")
            && !(sFileExtension === "f4v") && !(sFileExtension === "F4V")
            && !(sFileExtension === "mkv") && !(sFileExtension === "MKV")
        ) {
          this.error = "Falsches Dateiformat!";
        }
        /// OR together the accepted extensions and NOT it. Then OR the size cond.
        /// It's easier to see this way, but just a suggestion - no requirement.
        // if (!(sFileExtension === "pdf" ||
        //     sFileExtension === "doc" ||
        //     sFileExtension === "docx") || iFileSize > 10485760) { /// 10 mb
        //   txt = "File type : " + sFileExtension + "\n\n";
        //   txt += "Size: " + iConvert + " MB \n\n";
        //   txt += "Please make sure your file is in pdf or doc format and less than 10 MB.\n\n";
        //   alert(txt);
        // }
      }
      if (filesGesSize > 499.9) {
        this.error = "Alle Dateien zusammen dürfen max. 500MB groß sein!"
      }
    },
    getTeams: function () {
      if (this.user.role === 'trainer') {
        const formData = new FormData();
        formData.append("jwt", this.$getCookie('jwt'))

        this.$http.post(this.$api + "user/get_teams.php", formData, {},).then((res) => {
          if (res.data.success) {
            this.teams = res.data.data;
          }
        })
      }
    },
    openTeamChooseOverlay: function () {
      this.overlay = 3;
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }
      this.getDailyChallenge();
      this.getTeams();
      //set timer
      let nowDate = new Date();
      let countDownDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1, 23, 59, 59, 0);

      let firstTime = true;
      const self = this
      let timer = new this.timer(function () {

        if (firstTime === true) {
          timer.reset(45000);
          firstTime = false;
        }
        let now = new Date();
        let distance = countDownDate.getTime() - now.getTime();

        let hours = (countDownDate.getHours() - now.getHours())
        hours = hours < 10 ? '0' + hours : hours;

        let minutes = (countDownDate.getMinutes() - now.getMinutes())
        minutes = minutes < 10 ? '0' + minutes : minutes;
        self.countDown = "Noch " + hours + ":"
            + minutes + "h";

        if (distance < 0) {
          timer.stop()
          this.$router.go(0);
          self.countDown = "Beendet";
        }
      }, 100);
    });

  }
}
</script>

<style scoped>
.cardInternal {
  width: 85%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
  position: relative;
}

#dailyC {
  cursor: pointer;
  transition: all .3s;
}

#dailyC:hover {
  transform: scale(1.1);
}

#challengeData {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.cardInternal img {
  width: 35%;
}

#cName {
  color: var(--blue);
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 5px;
}

#cAdditional {
  color: var(--red);
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 10px;
}


#cTimer {
  color: var(--yellow);
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}

#descriptionD {
  margin-top: 20px;
  text-align: left;
}

#descriptionHeadline {
  color: black;
  font-size: 0.8rem;
  font-weight: bold;
}

.beweiseInfo {
  margin: 5px auto;
  width: 80%;
  font-size: 0.6rem;
  color: var(--gray);
}

#description, #doText {
  font-size: 0.8rem;
}

#description {
  margin-top: 5px;
  color: var(--ltgray);
}

#doText {
  margin-top: 10px;
  color: var(--red);
}

label#fileUploadButton {
  background-color: white;
  color: #646464;
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 50px;
  border: 1px solid var(--ltgray);
  padding: 18px 15px;
  border-radius: 10px;
  display: block;
  font-size: 0.8rem;
  text-align: left;
}

form .label {
  text-align: center;
  margin-top: 14px;
  margin-bottom: -10px;
  font-size: 0.8rem;
}

.maxPoints {
  font-size: 0.7rem;
  color: var(--blue);
  margin-top: 15px;
}

.gesPoints {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--yellow);
  margin-top: 5px;
}

.minPoints {
  color: var(--ltgray);
  font-size: 0.6rem;
}

#filesToUpload {
  text-align: left;
  width: 80%;
  margin: 20px auto 0 auto;
}

.fileList {
  margin-top: 10px;
  font-size: 0.7rem;
  position: relative;
  padding: 10px;
  border: 2px solid var(--blue);
  border-radius: 5px;
}

.teamName {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.noData {
  margin: 20px auto 0 auto;
  font-size: 1rem;
  width: 90%;
  color: var(--gray);
  padding: 15px 25px;
  max-width: 400px;
  min-width: 270px;
}

.red {
  color: var(--red);
}
</style>