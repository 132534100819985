<template>
  <div class="card cardInternal">
      <div class="name">{{ name }}</div>
      <div class="additionalInfo">{{ info }}</div>
  </div>
</template>

<script>
export default {
  name: "TextCard",
  props: {
    name: String,
    info: String,
  }
}
</script>

<style scoped>
.cardInternal {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 88%;
  margin: 15px auto 0 auto;
  max-width: 350px;
  min-width: 320px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all .3s;
}
.cardInternal:hover {
  transform: scale(1.1);
}

.name,.additionalInfo{
  font-size: 1.2rem;
  font-weight: bold;
}
.name{
  color: var(--blue);
}
.additionalInfo{
  color: var(--yellow);
}

</style>