<template>
  <div>
    <Header page-text="Mannschaft"></Header>
    <div class="card cardInternal" v-if="overlay === 0 && user && team">
      <div class="teamName">{{ team.teamName }}</div>
      <div id="sport">Sport: <span id="sportMarked">{{ team.sport }}</span></div>
      <div class="addPeople">
        <div class="addText">Trainer/innen</div>
        <div class="addButton blue" v-on:click="openAddOverlay('trainer')"><img src="../assets/icons/plus.svg"
                                                                                alt="Plus"></div>
      </div>
      <div class="peopleList">
        <div class="peopleCard blueB" v-for="(trainer,index) in team.trainer" :key="index">
          {{ trainer.firstName + " " + trainer.lastName }}
        </div>
      </div>
      <div class="addPeople">
        <div class="addText">Sportler/innen</div>
        <div class="addButton red" v-on:click="openAddOverlay('athlete')"><img src="../assets/icons/plus.svg"
                                                                               alt="Plus"></div>
      </div>
      <div class="peopleList">
        <div class="peopleCard redB" v-for="(athlete,index) in team.athletes" :key="index">
          {{ athlete.firstName + " " + athlete.lastName }}
        </div>
      </div>
    </div>

    <div class="card cardInternal" v-if="overlay === 1 && user && team">
      <CloseButton v-on:click.native="overlay = 0" color="var(--gray)"></CloseButton>
      <div class="teamName">{{ this.role === 'athlete' ? 'Sportler/innen' : 'Trainer/innen' }} hinzufügen</div>
      <form @submit="checkForm">
        <input v-for="(comp, index) in addPeople" :key="index" type="text" placeholder="Name/Kürzel" :name="'f'+index"
               :id="'f'+index" v-on:keyup="addInputField(index)">
        <InputSubmit value="Hinzufügen"/>
      </form>
    </div>

    <div class="card cardInternal" v-if="overlay === 2">
      <div class="teamName">{{ this.role === 'athlete' ? 'Sportler/innen' : 'Trainer/innen' }} erstellt</div>
      <div id="doneHeadline">Die Accounts wurden zum Registrieren freigegeben. Schicken Sie nun den folgenden Text an
        die {{ this.role === 'athlete' ? 'Sportler/innen' : 'Trainer/innen' }}. Er wurde automatisch Kopiert!
      </div>
      <div id="registerText">
        <div class="registerTextB" v-for="regLink in people_to_add" :key="regLink">{{ regLink }}</div>
      </div>
      <div id="copyButton" v-on:click="copyTextToClipboard(textToCopy)">Kopieren</div>
      <div id="closeButton" v-on:click="overlay = 0">Zurück</div>
      <div style="color:var(--red);font-size: 0.8rem; margin-top:8px">Schließen Sie die Seite erst, wenn der Text versendet wurde! Achtung die erstellten Konten, werden erst nach der Registrierung des nutzers in die vorheriege list aufgenommen!</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import InputSubmit from "@/components/InputSubmit";
import CloseButton from "@/components/CloseButton";

export default {
  name: "TeamManagement",
  components: {CloseButton, InputSubmit, Header},
  data() {
    return {
      user: null,
      team: null,
      overlay: 0,
      role: "",
      addPeople: [null],
      people_to_add: [],
      textToCopy: ""
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();

      this.people_to_add = [];
      for (let i = 0; i < this.addPeople.length; i++) {
        let doc = document.getElementById('f' + i).value;
        if (doc !== "") {
          this.people_to_add.push(doc);
        }
      }
      const formData = new FormData();
      formData.append('tid', this.$route.params.teamid);
      formData.append('role', this.role);
      formData.append('members', JSON.stringify(this.people_to_add));
      formData.append("jwt", this.$getCookie('jwt'))

      this.$http.post(this.$api + "user/register_tm.php", formData, {}).then((res) => {
        if (res.data.success) {
          this.textToCopy = "";

          for (let i = 0; i < this.people_to_add.length; i++) {
            this.people_to_add[i] = this.people_to_add[i].concat(": " + this.$server + "register/" + res.data.data[i]);
            this.textToCopy = this.textToCopy.concat(this.people_to_add[i] +"\r\n")
          }

          this.copyTextToClipboard(this.textToCopy);
          this.openCopyOverlay();
          return;
        }
        this.overlay = 0;
        this.$snackbar("Es ist ein Fehler aufgetreten!");
      })

    },
    openAddOverlay: function (role) {
      this.role = role;
      this.overlay = 1;
    },
    openCopyOverlay: function () {
      this.overlay = 2;
    },
    addInputField: function (index) {
      let doc = document.getElementById('f' + index).value;
      if (index === this.addPeople.length - 1 && doc !== "") {
        this.addPeople.push(null);
      } else if (index !== 0 && index === this.addPeople.length - 2 && doc === "") {
        this.addPeople.pop();
      }
    },
    copyTextToClipboard: function (value) {
      this.$snackbar("Kopiert");
      const tempInput = document.createElement("textarea");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      } else if (this.user.role !== 'trainer') {
        this.$router.push({name: "home"});
        return;
      }
      if (this.user.role === 'trainer') {
        const formData = new FormData();
        formData.append("jwt", this.$getCookie('jwt'))
        formData.append("tid", this.$route.params.teamid)
        this.$http.post(this.$api + "user/get_complete_team.php", formData, {},).then((res) => {
          if (res.data.success) {
            this.team = res.data.data;
          }
        })
      }
    });
  }

}
</script>

<style scoped>
.cardInternal {
  width: 90%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
  position: relative;
}

.teamName, .addText {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

#sport {
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  color: black;
  text-align: left;
}

#sportMarked {
  color: var(--blue);
}

.addPeople {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.addButton {
  padding: 5px 10px;
  border-radius: 10px;
}

.addText {
  padding-top: 3px;
}

.peopleList {
  margin-top: 15px;
}

.peopleCard {
  margin-top: 10px;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  border: 2px solid;
  border-radius: 10px;
  font-size: 0.8rem;
}


#doneHeadline {
  margin-top: 8px;
  font-size: 0.8rem;
}

#registerText {
  margin-top: 15px;
  border: 1px solid var(--blue);
  border-radius: 5px;
  text-align: center;
  color: var(--gray);
  line-height: 1.4rem;
  font-size: 0.8rem;
}
#copyButton{
  color: white;
  background-color: var(--red);
  padding: 8px 10px;
  border-radius: 5px;
  margin: 30px 20px;
  font-size: 1rem;
  font-weight: bold;
}
#closeButton{
  color: white;
  background-color: var(--blue);
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
}

.red {
  background-color: var(--red);
}

.redB {
  border-color: var(--red);
}

.blue {
  background-color: var(--blue);
}

.blueB {
  border-color: var(--blue);
}
</style>