import Vue from 'vue'
import App from './App.vue'
import router from './routing/index'
import axios from "axios";
import {Datetime} from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

Vue.config.productionTip = false
Vue.prototype.$http = axios
//local
//Vue.prototype.$api = "http://localhost/api/";
//server
Vue.prototype.$api = "https://psv.paderschwimmcup.de/api/";
Vue.prototype.$server = "https://psv.paderschwimmcup.de/#/";

Vue.prototype.$getUser = function getUser() {
    const formData = new FormData();
    formData.append("jwt", this.$getCookie('jwt'))

    return this.$http.post(this.$api + "user/validate_token.php", formData, {},).then((res) => {
        if (!res.data.success) {
            return null;
        } else {
            return res.data.data;
        }
    })
}

//set new Cookie
Vue.prototype.$setCookie = function (cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//get the Cookie
// get or read cookie
Vue.prototype.$getCookie = function (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

Vue.prototype.$isNumeric = function (str) {
    if (typeof str != "string") return false
    return !isNaN(str) && !isNaN(parseFloat(str))
}

Vue.prototype.$snackbar = function (value) {
    const tempInput = document.createElement("div");
    tempInput.setAttribute("id", "snackbar");
    tempInput.innerHTML = value;
    document.body.appendChild(tempInput);
    tempInput.className = "show";
    setTimeout(function () {
        document.body.removeChild(tempInput);
    }, 3000);

}


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')

Vue.component('datetime', Datetime);