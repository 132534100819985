import Vue from 'vue'
import Router from 'vue-router'
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import ScoreBoard from "@/pages/ScoreBoard";
import DailyChallenge from "@/pages/DailyChallenge";
import Home from "@/pages/Home";
import TeamManagement from "@/pages/TeamManagement";
import Battles from "@/pages/Battles";
import Challenges from "@/pages/Challenges";
import Verify from "@/pages/Verify";
import OwnScoreBoard from "@/pages/OwnScoreBoard";
import ChangeUser from "@/pages/ChangeUser";
import Converter from "@/pages/Converter";
import Attribution from "@/pages/Attribution";
import TrainerScoreBoard from "@/pages/TrainerScoreBoard";

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        title: 'Startseite',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        title: 'Einloggen',
        component: Login
    },
    {
        path: '/register/:registerkey',
        name: 'register',
        title: 'Registrieren',
        component: Register,
    },
    {
        path: '/user',
        name: 'changeUser',
        title: 'Benutzer',
        component: ChangeUser,
    },
    {
        path: '/convert',
        name: 'converter',
        title: 'Conversion',
        component: Converter,
    },
    {
        path: '/attribution',
        name: 'attribution',
        title: 'Attribution',
        component: Attribution,
    },
    {
        path: '/team/:teamid/battle/:bid/score/',
        name: 'score',
        title: 'Ranking',
        component: ScoreBoard,
    },
    {
        path: '/team/:teamid/battle/:bid/myscore/',
        name: 'myscore',
        title: 'Punkte',
        component: OwnScoreBoard,
    },
    {
        path: '/team/:teamid/battle/:bid/allscore/',
        name: 'allscore',
        title: 'Team Punkte',
        component: TrainerScoreBoard,
    },
    {
        path: '/team/:teamid/battle/:bid/challenge/',
        name: 'challenge',
        title: 'Challenges',
        component: DailyChallenge,
    },
    {
        path: '/team/:teamid',
        name: 'team',
        title: 'Mannschaft',
        component: TeamManagement,
    },
    {
        path: '/team/:teamid/battle',
        name: 'battle',
        title: 'Battles',
        component: Battles,
    },
    {
        path: '/team/:teamid/battle/:bid/challengeM',
        name: 'challengesManager',
        title: 'Challenge',
        component: Challenges,
    },
    {
        path: '/verify',
        name: 'verify',
        title: 'Bestätigen',
        component: Verify
    }
];

export default new Router({
    routes,
});
