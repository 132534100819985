<template>
  <div style="margin-bottom: 20px">
    <Header page-text="Benutzer"></Header>
      <div v-if="user">
        <div v-if="overlay === false">
          <div class="card cardInternal">
            <div class="headline">Vorname: <span class="infoData">{{user.firstName}}</span></div>
            <div class="headline">Nachname: <span class="infoData">{{user.lastName}}</span></div>
            <div class="headline">Benutzername: <span class="infoData">{{user.username}}</span></div>
            <div id="updateButton" v-on:click="overlay = true">Ändern</div>
          </div>
        </div>
        <div v-if="overlay === true">
          <div class="card cardInternal">
            <form @submit="checkForm">
              <InputText type="text" placeholder="Vorname (im Ranking für alle Sichtbar)" name="firstname" id="firstname" :value="user.firstName"
                         label=""></InputText>
              <InputText type="text" placeholder="Nachname (im Ranking für alle Sichtbar)" name="lastname" id="lastname" :value="user.lastName"
                         label=""></InputText>
              <div class="errorText" v-if="(error!=='')">{{ error }}</div>
              <InputSubmit value="Ändern"/>
            </form>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import InputText from "@/components/InputText";
import InputSubmit from "@/components/InputSubmit";

export default {
  name: "ChangeUser",
  components: {InputSubmit, InputText, Header},
  data() {
    return {
      user: null,
      overlay:false,
      error:'',
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      let firstName = document.getElementById("firstname").value;
      let lastName = document.getElementById("lastname").value;

      if (firstName === "") {
        this.error = 'Bitte Vornamen eingeben!';
      } else if (lastName === "") {
        this.error = 'Bitte Nachnamen eingeben!';
      }  else {
        this.error = '';

        const formData = new FormData();
        //console.log(this.$route.params.registerkey);
        formData.append("jwt", this.$getCookie('jwt'));
        formData.append("firstName",firstName);
        formData.append("lastName",lastName);
        this.$http.post(this.$api + "user/update_user.php", formData, {}).then((res) => {
          //console.log(res.data)
          if (!res.data.success) {
            this.error = res.data.error;
          } else {
            this.$setCookie("jwt", res.data.data, 12);
            this.user.firstName = firstName;
            this.user.lastName = lastName;
            this.overlay = false;
          }
        })
      }
    }
  },
  created() {
    this.$getUser().then(result => {
      this.user = result
      if (this.user === null) {
        this.$setCookie('jwt', '', 1);
        this.$router.push({name: "login"});
        return;
      }
    });
  }
}
</script>

<style scoped>
.cardInternal{
  width: 85%;
  max-width: 400px;
  min-width: 270px;
  padding: 15px 20px;
  margin: 20px auto;
  position: relative;
}

.headline {
  margin-top: 5px;
  text-align: left;
  font-size: 1rem;
  color: black;
}

.infoData {
  color: var(--blue);
  font-weight: bold;
  font-size: 1.2rem;
}
#updateButton{
  margin-top: 40px;
  padding: 10px 50px;
  background-color: var(--red);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
}
</style>