<template>
  <div class="close" :style="'color:'+color+';'">X</div>
</template>

<script>
export default {
name: "CloseButton",
  props:{
    color:String,
  }
}
</script>

<style scoped>
.close{
  padding: 5px;
  position: absolute;
  top:10px;
  right:10px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}
</style>